import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { formatDuration } from "@/app/util.mjs";
// import MatchAccolades from "@/game-deadlock/components/MatchAccolades.jsx";
import MatchScoreboard from "@/game-deadlock/components/MatchScoreboard.jsx";
import Assets from "@/game-deadlock/utils/Assets.mjs";
import getPlayer from "@/game-deadlock/utils/get-player.mjs";
import { playerAccolades } from "@/game-deadlock/utils/player-match-accolades.mjs";
import DotIcon from "@/inline-assets/dot.svg";
import { SharedMatchLayout } from "@/shared/Match.jsx";
import { MainColumnsContainer } from "@/shared/Match.style.jsx";
import Badge, { BadgeList } from "@/shared/PostMatchBadge.jsx";
import ShareButton from "@/shared/ShareButton.jsx";
import { TimeAgo } from "@/shared/Time.jsx";
import { useEvalState } from "@/util/eval-state.mjs";
import { sanitizeNumber } from "@/util/helpers.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function Match() {
  const { t } = useTranslation();
  const {
    parameters: [matchId, steamId],
  } = useRoute();

  const {
    deadlock: { matches },
  } = useSnapshot(readState);

  const $match = matches[matchId];
  const match = useEvalState($match);
  const player = useMemo(() => getPlayer(steamId, match), [steamId, match]);

  const isWin = useMemo(
    () => !!(match && player && match?.winning_team === player.team),
    [match, player],
  );

  const accolades = useMemo(() => {
    return playerAccolades({ steamId, matchData: match });
  }, [steamId, match]);

  return (
    <SharedMatchLayout
      match={$match}
      title={
        match && player && match.winning_team === player.team
          ? t("common:victory", "Victory")
          : t("common:defeat", "Defeat")
      }
      image={
        Assets.getHeroPortrait(player?.hero_id) || Assets.getDeadlockLogo()
      }
      imageLink={`/deadlock/profile/${steamId}`}
      borderColor={isWin ? "var(--turq)" : "var(--red)"}
      underTitle={
        <div className="type-body2 flex align-center gap-2 color-shade2">
          {[
            match && player && match.winning_team === player.team
              ? t("common:victory", "Victory")
              : t("common:defeat", "Defeat"),
            ,
            match?.duration_s > 0 &&
              t("common:minutes", "{{minutes}} mins", {
                minutes: formatDuration(
                  sanitizeNumber(match.duration_s * 1000),
                  "m:ss",
                ),
              }),
            matchId &&
              t("deadlock:matchId", "Match ID: {{matchId}}", { matchId }),
          ]
            .filter(Boolean)
            .map((i) => (
              <React.Fragment key={i}>
                <span className="color-shade2">{i}</span>
                <DotIcon style={{ color: "var(--shade3)" }} />
              </React.Fragment>
            ))}
          {!!match?.start_time && (
            <div className="color-shade2">
              <TimeAgo
                date={new Date(match.start_time * 1000)}
                className={undefined}
              />
            </div>
          )}
          <DotIcon style={{ color: "var(--shade3)" }} />
          <ShareButton />
        </div>
      }
      badges={
        accolades.length ? (
          <BadgeList className="span-all">
            {accolades.map((a, i) => {
              return <Badge key={i} accolade={a.accolade} />;
            })}
          </BadgeList>
        ) : null
      }
    >
      <MainColumnsContainer>
        {/* <div
          className="span-1 flex direction-column"
          style={{ display: "flex" }} // This overrides MainColumnsContainer's display grid
        >
          <MatchAccolades />
        </div> */}
        <MatchScoreboard
          matchId={matchId}
          steamId={steamId}
          className="span-3"
        />
      </MainColumnsContainer>
    </SharedMatchLayout>
  );
}

export function meta([_, steamId]) {
  const name = readState.deadlock.steam[steamId]?.name ?? "Unknown";
  return {
    title: [
      "deadlock:meta.match.title",
      "{{name}}'s Match Stats – Blitz Deadlock",
      { name },
    ],
    description: [
      "deadlock:meta.match.description",
      "Match Stats for {{name}}",
      { name },
    ],
  };
}
